@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.info {
    width: 100%;
    height: inherit;
    font-size: 0.9em;
    line-height: 0.9em;
    display: grid;
    grid-template-rows: 1fr 100px 60px;
    &__description {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__table {
        font-size: 0.8em;
        table-layout: fixed;
        width: 100%;
        max-height: 120px;
        border: 2px solid $primary-color;

        td {
            border: 1px solid $primary-color;
            text-align: center;
        }
    }

    &__links {
        display: flex;
        gap: 3px;

        a {
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            color: black;
            width: 50%;
            height: 100%;
            background-color: fade-out($color: $primary-color, $amount: 0.5);
            cursor: url(../../../../public/images/cursors-pointer.png), pointer;

            &:hover {
                background-color: fade-out($color: $primary-color,
                        $amount: 0.2);
            }
        }

        .git-logo {
            img {
                height: 40px;
            }

            &--inactive {
                background-color: rgba(128, 128, 128, 0.11);
                pointer-events: none;
            }
        }
    }
}

// When viewport width > md
@media screen and ( min-width: $md ) {
    // Increase font size and middle line.
    .info{
        font-size: 1em;
        line-height: 1em;
        border-right: thick double $primary-color;

        // Replace description table and increse font size.
        &__description {
                line-height: 1.5em;
                font-size: 1.4em;
        }
    }
    
}

// When viewport width > lg
@media screen and ( min-width: $xl ) {
    // Increase font size and middle line.
    .info{
        font-size: 1.2em;
        line-height: 1.2em;
        border-right: thick double $primary-color;

        // Replace description table and increse font size.
        &__description {
                align-items: flex-start;
                line-height: 1.5em;
                font-size: 1.4em;
                padding-top: 40%;
        }
    }
    
}

// when viewport width < $md and height < $sm ( mobile landscape mode )
@media screen and ( max-width: $md ) and ( max-height: $sm ) and (orientation: landscape) {
    .info{
        width : 100vw;
        border-right: thick double $primary-color;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto 60px;
        column-gap: 2px;

        &__description {
            padding: auto auto;
            overflow: hidden;
            grid-column-start: 1;
            grid-column-end: 2;
        }
        &__table{
            grid-column-start: 1;
            grid-column-end: 2;
        }

        &__links{
            grid-column-start: 2;
            grid-column-end: 3;
        }
    }
}