@use "./mixins" as *;
@use "./variables" as *;
@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");
/* Reset */
* {
    box-sizing: border-box;
    outline: none;
    margin: 0;
    padding: 0;
    color: rgb(29, 138, 7);
    font-family: "Ubuntu Mono", monospace;
}
html {
    height: 90vh;
    @include breakpoint(sm){
        height: strecth;
    }
}
ul {
    list-style: none;
}

a:link {
    text-decoration: none;
}
