@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/animations" as *;

.projects {
    position: relative;
    width: 100vw;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    &__navigation {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 100px;
        top : 50px;
        padding: 60px;
        z-index: 1000;
        perspective: 170vw;
        perspective-origin: 170% -60%;
        .scene {
            position: relative;
            transform-style: preserve-3d;
            .cube {
                position: static;
                transition: all 1s;
                cursor: url(../../../public/images/cursors-pointer.png), pointer;
                &--active {
                    transform: rotateY(180deg);
                }
                .cube__back {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

// when viewport width > $sm
@media screen and ( min-width: $sm ) {
    .projects{
        // place the navigation cubes at the bottom of the page
        &__navigation{
            top : auto;            
            bottom: 0px;
        }
    }
}

// when viewport width < $md and height < $sm ( mobile landscape mode )
@media screen and ( max-width: $md ) and ( max-height: $sm ) {
    .projects{
        &__navigation{
            top : -20px; 
            justify-content: flex-start;
            gap:50px;
            margin-left: 20px ;           
        }
    }
}