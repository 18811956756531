.cube {
    &--responsive {
        @include cube-setup($sm-cube-size);
        @include breakpoint(sm){
            @include cube-setup($md-cube-size);
            @include breakpoint_height( $sm, max ){
                @include cube-setup($sm-cube-size);
            }
        }
        @include breakpoint(md){
            @include cube-setup($lg-cube-size);
            @include breakpoint_height( $sm, max ){
                @include cube-setup($sm-cube-size);
            }
        }
        @include breakpoint(lg) {
            @include cube-setup($xl-cube-size);
            @include breakpoint_height( $sm, max ){
                @include cube-setup($sm-cube-size);
            }
        }
        @include breakpoint(xl){
            @include cube-setup($xl-cube-size);
            @include breakpoint_height( $sm, max ){
                @include cube-setup($sm-cube-size);
            }
        }
    }
    &--sm {
        @include cube-setup($sm-cube-size);
    }
    &--xs {
        @include cube-setup($xs-cube-size);
    }
    &--xxs {
        @include cube-setup($xxs-cube-size);
    }
    &--responsive-xxxs {
        @include breakpoint_height( $sm, max ){
            @include cube-setup($xxxs-cube-size);
            @include breakpoint( $md ){
                @include cube-setup($xxs-cube-size);
            }
        }
    }
    &__image {
        width: 100%;
        height: 100%;
    }
    &--green {
        & > * {
            border: 2px;
            box-shadow: 0 0 10px $primary-color inset,
                0 0 2px lighten($primary-color, 20%);
        }
    }
    &--inactive {
        & > * {
            border: 2px;
            box-shadow: 0 0 5px fade-out($primary-color, 0.5) inset,
                0 0 15px fade-out($primary-color, 0.6);
        }
    }
    &--active {
        & > * {
            font-size: 25px;
            font-weight: 800;
            color: lighten($primary-color, 20%);
            border: 2px;
            box-shadow: 0 0 10px $primary-color inset,
                0 0 15px lighten($primary-color, 20%);
            -moz-box-shadow: 0 0 10px $primary-color inset,
                0 0 15px lighten($primary-color, 20%);
            -webkit-box-shadow: 0 0 10px $primary-color inset,
                0 0 15px lighten($primary-color, 20%);
        }
    }
}
