@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.Vslide {
    display: flex;

    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    transform: translateY(0);
    transition: 1000ms cubic-bezier(0.78, -0.36, 0.15, 1.3);
    transition-delay: 500ms;
    cursor: url(../../../public/images/cursors-wheel.png), crosshair;

    &--up {
        transform: translateY(-100%);
        transition: 1300ms cubic-bezier(0.78, -0.36, 0.23, 1.34);
    }
    &--down {
        transform: translateY(100%);
        transition: 1300ms cubic-bezier(0.78, -0.36, 0.23, 1.34);
    }
}
