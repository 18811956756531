@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.about {
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    @include breakpoint(sm) {
        font-size: 1.5em;
    }
    &__container {
        position: relative;
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-rows: auto 1fr auto;
        line-height: 150%;
        border: thick double $primary-color;
        scrollbar-color: $primary-color $primary-color;
        @include breakpoint(sm) {
            width: min(85vw, 800px);
            height: 80vh;
        }
    }
    header {
        height: 70px;
        width: 100%;
        background-color: fade-out($color: $primary-color, $amount: 0.5);
        border-bottom: thick double $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7em;
        h2 {
            color: black;
        }
    }
    .profile {
        position: absolute;
        width: min(40vw, 300px);
        min-width: 160px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
    }
    &__info {
        flex-grow: 1;
        opacity: 0;
        transition: 0s;
        transition-delay: 0s;
        overflow-y: scroll;
        padding: 0 10px;
        & * {
            padding: 5px 0;
        }
        &--show {
            position: static;
            transition-delay: 4s;
            opacity: 1;
            z-index: 900;
        }
        &__info::-webkit-scrollbar {
            width: 11px;
        }
        &__info {
            scrollbar-width: thin;
            scrollbar-color: $primary-color black;
        }
        &__info::-webkit-scrollbar-track {
            background: $primary-color;
        }
        &__info::-webkit-scrollbar-thumb {
            background-color: black;
        }
    }
    b {
        background-color: $primary-color;
        color: black;
        font-size: 0.8em;
    }
    .buttons {
        width: 100%;
        height: 50px;
        display: flex;
        gap: 3px;
        font-size: 0.8em;
        button {
            width: 50%;
            height: 100%;
            border: none;
            color: black;
            font-size: 1.2em;
            background-color: fade-out($color: $primary-color, $amount: 0.5);
            cursor: url(../../../public/images/cursors-pointer.png), pointer;
            z-index: 1000;
            &:hover {
                background-color: fade-out(
                    $color: $primary-color,
                    $amount: 0.2
                );
            }
            &.active {
                background-color: fade-out(
                    $color: $primary-color,
                    $amount: 0.2
                );
            }
        }
    }
}
