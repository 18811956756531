@use "./mixins" as *;

@keyframes rotate {
    0% {
        transform: rotateX(0deg) rotateY(0) rotateZ(0deg);
    }
    100% {
        transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}

@keyframes appear {
    0% {
        visibility: hidden;
    }
    90% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

@keyframes logoStroke {
    100% {
        stroke-dashoffset: 526;
    }
}
@keyframes logoFill {
    100% {
        fill: rgb(29, 138, 7);
        stroke: rgb(10, 10, 10);
        transform: translate(calc(-50% - 2.5em), -70%);
    }
}

@keyframes cube-open {
    0% {
    }
}

@keyframes spinY {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(180deg);
    }
}

@keyframes spinZ {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

@keyframes spinZ_180X {
    0% {
        transform: rotateX(180deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(180deg) rotateZ(360deg);
    }
}

@keyframes spinX {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(180deg);
    }
}

@keyframes blink {
    0%,
    49% {
        visibility: hidden;
    }
    50%,
    100% {
        visibility: visible;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes scale-in {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes scale-out {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}

@keyframes slide-down {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}
