@use "./styles/variables" as *;
@use "./styles/mixins" as *;
@import "./styles/globals";
@import "./styles/cubes";

.resize-transition-stopper * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

transition-stopper {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    transition-delay: 0s;
}

html {
    position: fixed;
    height: 100%;
    overflow: hidden;

    body {
        margin: 0;
        width: 100vw;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        background: repeating-linear-gradient(
            60deg,
            black,
            rgb(17, 17, 17),
            black 4px
        );
        &:hover {
            cursor: url(../public/images/cursors-arrow.png), auto;
        }
        #root {
            height: 100%;
            transform-style: preserve-3d;

            main {
                height: 100%;
                width: 100vw;
                overflow: hidden;
            }
        }
    }
}
