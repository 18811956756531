@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.text {
    width: 100vw;
    height: 100%;
    display: grid;
    grid-template-rows: 120px 1fr;
    grid-template-columns: 100%;
    overflow: hidden;
    border: thick double $primary-color;
    @include breakpoint_height( sm, max ){
        grid-template-rows: 90px 1fr;
        grid-template-columns: 50% 50%;
    }
    @include breakpoint_height( sm ){
        @include breakpoint(sm){
            width: 85vw;
            height: 80%;
            margin: 70px 7.5vw;
        }
        @include breakpoint(md) {
            grid-template-rows: 70px 1fr;
        }
    }
    &__title {
        height: 100%;
        background-color: fade-out($color: $primary-color, $amount: 0.5);
        border-bottom: thick double $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
        @include breakpoint_height( sm, max ){
            grid-column-start: 1;
            grid-column-end: 3;
            justify-content: start;
            align-items: start;
            padding:10px 10px;
        }
        @include breakpoint_height( sm ){
            @include breakpoint(md) {
                grid-column-start: 1;
                grid-column-end: 3;
            }
    }
        h2 {
            color: black;
        }
    }
    &__container{
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
