@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;
@use "../../../styles/animations" as *;

.layout {
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 500vw;
    perspective-origin: -50% 0%;
    pointer-events: none;
    &--grab {
        &:hover {
            cursor: url(../../../../public/images/cursors-grab.png), grab;
        }

        &--onMotion {
            &:hover {
                cursor: url(../../../../public/images/cursors-grabbing.png),
                    grabbing;
            }
        }
    }

    .scene {
        transform-style: preserve-3d;
        animation: none;

        &--autoRotation {
            animation: rotate 30s infinite linear;
        }
    }
}

.buttons {
    width: 100%;
    height: 60px;
    display: none;
    gap: 3px;
    button {
        width: 50%;
        height: 100%;
        border: none;
        color: black;
        font-size: 1.2em;
        background-color: fade-out($color: $primary-color, $amount: 0.7);
        cursor: url(../../../../public/images/cursors-pointer.png), pointer;

        &:hover {
            background-color: fade-out($color: $primary-color, $amount: 0.2);
        }

        &.active {
            background-color: fade-out($color: $primary-color, $amount: 0.2);
        }
    }
}


@media screen and ( min-width: $md ){
    .layout {
        pointer-events: auto;
        width: 50vw;
        height: calc(100vh - 200px);
    }
    .buttons {
        display: flex;
    }
}

// when viewport width < $md and height < $sm ( mobile landscape mode )
@media screen and ( max-width: $md ) and ( max-height: $sm ) {

}