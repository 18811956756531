@import url("https://fonts.googleapis.com/css2?family=Frijole&display=swap");

/* Variables */

$font-principal: "Frijole", cursive;
$font-size: 1.5rem;
$navbar-bg-color: rgba(0, 0, 0, 0);

/* Colors */
$primary-color: #1d8a07;
$complementary-color: #74078a;
$secondary-color: black;
$active-color: rgb(255, 145, 0);

/* Menu */
$navbar-color: rgb(0, 0, 0);
$menu-color: rgb(141, 141, 141);
$menu-bg-color: rgba(0, 0, 0, 0.945);

/* Cube */
$cube_face-color: rgba(0, 0, 0, 0.836);
$xxl-cube-size: 200px;
$xl-cube-size: 150px;
$lg-cube-size: 100px;
$md-cube-size: 70px;
$sm-cube-size: 50px;
$xs-cube-size: 16px;
$xxs-cube-size: 12px;
$xxxs-cube-size:6px;

/* button */
$button-size: 100px;


/* Breakpoints */
$sm : 576px;
$md : 768px;
$lg : 992px;
$xl : 1200px;
$xxl: 1400px;

$breakpoints: (
    sm: (
        min: 576px,
        max: 575.98px
    ),
    md: (
        min: 768px,
        max: 767.98px
    ),
    lg: (
        min: 992px,
        max: 991.98px
    ),
    xl: (
        min: 1200px,
        max: 1199.98px
    ),
    xxl: (
        min: 1400px,
        max: 1399.98px
    )
);

$breakpoints_height: (
    sm: (
        min: 576px,
        max: 575.98px
    ),
    md: (
        min: 768px,
        max: 767.98px
    ),
    lg: (
        min: 992px,
        max: 991.98px
    ),
    xl: (
        min: 1200px,
        max: 1199.98px
    ),
    xxl: (
        min: 1400px,
        max: 1399.98px
    )
);