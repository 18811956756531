@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.profile {
    .svg {
        display: inline-block;
        stroke: rgb(29, 138, 7);

        path {
            fill-opacity: 0;
            stroke-width: 4;
            stroke-opacity: 0;
            stroke-dasharray: 100%;
            stroke-dashoffset: 100%;
            transition: all 5s, stroke-opacity 4s 3s, stroke-dashoffset 4s 1s;
        }
        &--show {
            path {
                display: inline-block;
                transition: all 2s, stroke-dashoffset 5s, fill-opacity 6s 3s,
                    stroke-width 2.5s 4s;
                stroke-opacity: 1;
                stroke-width: 0;
                fill-opacity: 0.7;
                stroke-dashoffset: 200%;
            }
            .path1 {
                fill: lighten($primary-color, 20%);
            }
            .path2 {
                fill: lighten($primary-color, 15%);
            }
            .path3 {
                fill: lighten($primary-color, 10%);
            }
            .path4 {
                fill: lighten($primary-color, 0);
            }
            .path5 {
                fill: darken($primary-color, 5%);
            }
            .path6 {
                fill: darken($primary-color, 15%);
            }
            .path7 {
                fill: darken($primary-color, 22%);
            }
        }
    }
}
