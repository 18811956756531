@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/animations" as *;

.nav {
    position: absolute;
    width: 97vw ;
    height: 0;
    padding: 10px;
    padding-top: 30px;
    padding-right: 35px;
    display: flex;
    justify-content: flex-end;
    perspective: 170vw;
    perspective-origin: 170% -60%;
    z-index: 200;
    @include breakpoint(sm){
    padding-right: max(calc(5% + 10px), 80px);
    }
    &--menu {
        .cube--menu {
            transform: rotateY(180deg);
            transition: all 1s cubic-bezier(0.37, -0.9, 0.51, 1.81);
        }
    }
    .scene {
        position: relative;
        transform-style: preserve-3d;
    }

    &__cube {
        transition: all 1s cubic-bezier(0.37, -0.9, 0.51, 1.81);
        z-index: 1000;
        pointer-events: auto;
        .x {
            display: inline;
            fill: rgb(29, 138, 7);
            stroke-width: 1px;
            stroke: rgb(29, 138, 7);
        }
        &:hover {
            transition: box-shadow 0.8s cubic-bezier(0.37, -0.9, 0.51, 2),
                transform 1s cubic-bezier(0.37, -0.9, 0.51, 1.81);
            cursor: url(../../../public/images/cursors-pointer.png), pointer;
            border: 2px;
            box-shadow: 0 10px 18px $primary-color inset,
                0 0 10px $primary-color;
            .x {
                display: inline;
                fill: lighten($primary-color, 40%);
                stroke-width: 1px;
                stroke: lighten($primary-color, 40%);
            }
        }
    }

    .menu {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: repeating-linear-gradient(
            150deg,
            black,
            rgb(17, 17, 17),
            black 4px
        );
        opacity: 0;
        visibility: hidden;
        transition: all 1s;
        transition-delay: 0.6s;

        &__links {
            width: 500px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            animation: scale-out 0.5s both ease;
            & > li {
                a {
                    display: block;
                    line-height: 25px;
                    padding: 1rem;
                    color: $primary-color;
                    font-size: $font-size;
                    transition:all 0.1s ease-in-out;
                    &:hover {
                        letter-spacing: 3px;
                    }
                }
                & > .active {
                    color: lighten($primary-color, 40%);
                }
            }
        }
        &--open {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
            & > ul {
                animation: scale-in 0.5s both ease 0.5s;
            }
        }
    }
}
